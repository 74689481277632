<template>
    <section id="content">
        <div class="container">
            <div class="c-header">
                <h2>
                    Add Staff Member
                    <small>Fields mark <strong>*</strong> are required </small>
                </h2>
            </div>

            <!-- <pre> {{ staff }} </pre> -->

            <div class="card" id="profile-main">
                <div class="pm-overview c-overflow">
                    <div class="pmo-pic">
                        <div class="p-relative">
                            <croppa v-model="myCroppa"
                                ref="staffPhoto"
                                :width="260"
                                :height="260"
                                :canvas-color="'#a4a4a4'"
                                :placeholder="'Click here to choose a picture'"
                                :placeholder-font-size="14"
                                :accept="'image/*'"
                                :disable-rotation="true"
                                :prevent-white-space="true"
                                :show-remove-button="true"
                                :remove-button-color="'red'"
                                :initial-image="'path/to/initial-image.png'"
                                @init="handleCroppaInit"
                                @file-choose="handleCroppaFileChoose"
                                @file-size-exceed="handleCroppaFileSizeExceed"
                                @file-type-mismatch="handleCroppaFileTypeMismatch"
                                @new-image-drawn="handleNewImage"
                                @image-remove="handleImageRemove"
                                @move="handleCroppaMove"
                                @zoom="handleCroppaZoom"></croppa>
                            <button class="btn btn-block btn-info" @click="selectPhoto">
                                Select Photo
                            </button>
                        </div>
                    </div>
                </div>

                <form @submit.prevent="saveStaff">
                    <div class="pm-body clearfix">
                        
                        <!-- Basic -->
                        <div class="pmb-block">
                            <div class="pmbb-header">
                                <h2><i class="zmdi zmdi-account m-r-5"></i> Basic Information</h2>
                            </div>
                            <div class="pmbb-body p-l-30">
                                <dl class="dl-horizontal">
                                    <dt class="p-t-10">Salutation</dt>
                                    <dd>
                                        <div class="fg-line">
                                            <select class="form-control" v-model="staff.title" required>
                                                <option v-for="title in $store.state.salutations" :key="title">{{ title }}</option>
                                            </select>
                                        </div>
                                    </dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt class="p-t-10">First Name</dt>
                                    <dd>
                                        <input type="text" class="form-control" v-model="staff.firstName" required>
                                    </dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt class="p-t-10">Last Name</dt>
                                    <dd>
                                        <input type="text" class="form-control" v-model="staff.lastName" required>
                                    </dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt class="p-t-10">Other Names</dt>
                                    <dd>
                                        <input type="text" class="form-control" v-model="staff.otherNames">
                                    </dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt class="p-t-10">Gender</dt>
                                    <dd>
                                        <div class="fg-line">
                                            <select class="form-control" v-model="staff.gender" required>
                                                <option>Male</option>
                                                <option>Female</option>
                                            </select>
                                        </div>
                                    </dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt class="p-t-10">Date of Birth</dt>
                                    <dd>
                                        <birth-datepicker 
                                            v-model="staff.dob" 
                                            class="form-control" 
                                            :high="false" 
                                            :closeOnSet="true" 
                                            :valueIsString="true" 
                                            :delimiter="'-'" 
                                            :attachment="'bottom-right'"/>
                                    </dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt class="p-t-10">Martial Status</dt>
                                    <dd>
                                        <div class="fg-line">
                                            <select class="form-control" v-model="staff.maritalStatus" required>
                                                <option v-for="status in $store.state.maritalStatuses" :key="status">{{ status }}</option>
                                            </select>
                                        </div>
                                    </dd>
                                </dl>
                                
                                <div v-if="staff.maritalStatus == 'Married'">
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Name of Spouse</dt>
                                        <dd>
                                            <input type="text" class="form-control" v-model="staff.spouse" />
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Marriage Anniversary</dt>
                                        <dd>
                                            <birth-datepicker 
                                                v-model="staff.marriageAnniversary" 
                                                class="form-control"
                                                :high="false" 
                                                :closeOnSet="true" 
                                                :valueIsString="true" 
                                                :delimiter="'-'" 
                                                :attachment="'bottom-right'"/>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>

                        <!-- Contact -->
                        <div class="pmb-block">
                            <div class="pmbb-header">
                                <h2><i class="zmdi zmdi-phone m-r-5"></i> Contact Information</h2>
                            </div>
                            <div class="pmbb-body p-l-30">
                                <div class="pmbb-view">
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Mobile Phone 1</dt>
                                        <dd>
                                            <div class="fg-line">
                                                <vue-tel-input @onInput="onInputTelephone1" mode="international" :enabledFlags="true" :enabledCountryCode="false" :disabledFormatting="true" wrapperClasses="telinput" inputId="telephone1" placeholder="" v-model="staff.telephone1" required></vue-tel-input>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Mobile Phone 2</dt>
                                        <dd>
                                            <div class="fg-line">
                                                <vue-tel-input @onInput="onInputTelephone2" mode="international" :enabledFlags="true" :enabledCountryCode="false" :disabledFormatting="true" wrapperClasses="telinput" inputId="telephone2" placeholder="" v-model="staff.telephone2"></vue-tel-input>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Email Address 1</dt>
                                        <dd>
                                            <div class="fg-line">
                                                <input type="email" class="form-control" v-model="staff.email1" required>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Email Address 2</dt>
                                        <dd>
                                            <div class="fg-line">
                                                <input type="email" class="form-control" v-model="staff.email2">
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        
                        <!-- Employment -->
                        <div class="pmb-block">
                            <div class="pmbb-header">
                                <h2><i class="zmdi zmdi-account m-r-5"></i> Employment Information</h2>
                            </div>
                            <div class="pmbb-body p-l-30">
                                <div class="form-group m-b-30">
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Category</dt>
                                        <dd>
                                            <select class="form-control" v-model="staff.category" required>
                                                <option v-for="category in $store.state.categories" :key="category">{{ category }}</option>
                                            </select>
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Assignment</dt>
                                        <dd>
                                            <select class="form-control" v-model="staff.assignment" required>
                                                <option v-for="assignment in $store.state.staffAssignments" :key="assignment">{{ assignment }}</option>
                                            </select>
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Region of Assignment</dt>
                                        <dd>
                                            <select class="form-control" v-model="staff.regionAssigned" required>
                                                <option v-for="region in $store.state.regionList" :key="region">{{ region }}</option>
                                            </select>
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">City of Assignment</dt>
                                        <dd>
                                            <input type="text" class="form-control" v-model="staff.cityAssigned">
                                        </dd>
                                    </dl>                                    
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Date Joined</dt>
                                        <dd>
                                            <birth-datepicker  required
                                                v-model="staff.dateJoined" 
                                                class="form-control" 
                                                :high="false" 
                                                :closeOnSet="true" 
                                                :valueIsString="true" 
                                                :delimiter="'-'" 
                                                :attachment="'bottom-right'"/>
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">SSNIT Number</dt>
                                        <dd>
                                            <input type="text" class="form-control" v-model="staff.ssnit">
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>

                        <!-- Leadership Roles -->
                        <div class="pmb-block">
                            <div class="pmbb-header">
                                <h2>
                                    <i class="zmdi zmdi-edit m-r-5"></i>
                                    Leadership Roles
                                </h2>
                            </div>
                            <div class="pmbb-body p-l-30">
                                <div class="form-group m-b-30" v-for="(role, index) in staff.leadershipRoles" :key="index">
                                
                                    <button class="btn btn-danger btn-icon btn-remove" @click="removeRole(index)" v-if="index > 0">
                                        <i class="zmdi zmdi-close"></i>
                                    </button>
                                    
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Role</dt>
                                        <dd>
                                            <div class="fg-line">
                                                <select class="form-control" v-model="role.role">
                                                    <option v-for="role in $store.state.roles" :key="role">{{ role }}</option>
                                                </select>
                                            </div>
                                        </dd>
                                    </dl>
                                    
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Date Started</dt>
                                        <dd>
                                            <vue-monthly-picker 
                                                class="form-control" 
                                                :dateFormat="'MM-YYYY'"
                                                :clearOption="false"
                                                :monthLabels="monthLabels"
                                                v-model="role.started"></vue-monthly-picker>
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Is Current Role?</dt>
                                        <dd>
                                            <div class="fg-line">
                                                <div class="toggle-switch m-t-10">
                                                    <input :id="'current-' + index" type="checkbox" hidden="hidden" v-model="role.isCurrent">
                                                    <label :for="'current-' + index" class="ts-helper"></label>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal" v-if="!role.isCurrent">
                                        <dt class="p-t-10">Date Ended</dt>
                                        <dd>
                                            <vue-monthly-picker 
                                                class="form-control" 
                                                :dateFormat="'MM-YYYY'"
                                                :clearOption="false"
                                                :monthLabels="monthLabels"
                                                v-model="role.ended"></vue-monthly-picker>
                                        </dd>
                                    </dl>
                                    
                                </div>
                                <button type="button" class="btn btn-primary btn-sm" @click="addNewRole">Add New Position</button>
                            </div>
                        </div>

                        <!-- Identification -->
                        <div class="pmb-block">
                            <div class="pmbb-header">
                                <h2><i class="zmdi zmdi-account m-r-5"></i> Identification Information</h2>
                            </div>
                            <div class="pmbb-body p-l-30">
                                <dl class="dl-horizontal">
                                    <dt class="p-t-10">Current Passport</dt>
                                    <dd>
                                        <div class="row">
                                            <div class="col-md-7">
                                                <input v-model="staff.passportNumber" type="text" class="form-control" placeholder="Number">
                                            </div>
                                            <div class="col-md-5">
                                                <datetime v-model="staff.passportExpiry" format="dd-MM-yyyy" class="form-control" placeholder="Expiry Date"></datetime>
                                            </div>
                                        </div>
                                    </dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt class="p-t-10">Ghana card</dt>
                                    <dd>
                                        <div class="row">
                                            <div class="col-md-7">
                                                <input v-model="staff.ghanaCardNumber" type="text" class="form-control" placeholder="Number">
                                            </div>
                                            <div class="col-md-5">
                                                <datetime v-model="staff.ghanaCardExpiry" format="dd-MM-yyyy" class="form-control" placeholder="Expiry Date"></datetime>
                                            </div>
                                        </div>
                                    </dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt class="p-t-10">Voters ID</dt>
                                    <dd>
                                        <div class="row">
                                            <div class="col-md-7">
                                                <input v-model="staff.votersIdNumber" type="text" class="form-control" placeholder="Number">
                                            </div>
                                            <div class="col-md-5">
                                                <datetime v-model="staff.votersIdExpiry" format="dd-MM-yyyy" class="form-control" placeholder="Expiry Date"></datetime>
                                            </div>
                                        </div>
                                    </dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt class="p-t-10">Health Insurance No</dt>
                                    <dd>
                                        <div class="row">
                                            <div class="col-md-7">
                                                <input v-model="staff.healthInsuranceNumber" type="text" class="form-control" placeholder="Number">
                                            </div>
                                            <div class="col-md-5">
                                                <datetime v-model="staff.healthInsuranceExpiry" format="dd-MM-yyyy" class="form-control" placeholder="Expiry Date"></datetime>
                                            </div>
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                        </div>                        

                        <!-- Children -->
                        <div class="pmb-block">
                            <div class="pmbb-header">
                                <h2><i class="zmdi zmdi-phone m-r-5"></i> Children Information</h2>
                            </div>
                            <div class="pmbb-body p-l-30">
                                <div class="form-group m-b-30" v-for="(child, i) in staff.children" :key="i">
                                    
                                    <button class="btn btn-danger btn-icon btn-remove" @click="removeChild(i)" v-if="i > 0">
                                        <i class="zmdi zmdi-close"></i>
                                    </button>
                                    
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Full Name</dt>
                                        <dd>
                                            <div class="fg-line">
                                                <input type="text" class="form-control" v-model="child.name">
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Gender</dt>
                                        <dd>
                                            <div class="fg-line">
                                                <select class="form-control" v-model="child.gender">
                                                    <option>Male</option>
                                                    <option>Female</option>
                                                </select>
                                            </div>
                                        </dd>
                                    </dl>  
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Date of Birth</dt>
                                        <dd>
                                            <birth-datepicker 
                                                v-model="child.dob" 
                                                class="form-control" 
                                                :high="false" 
                                                :closeOnSet="true" 
                                                :valueIsString="true" 
                                                :delimiter="'-'" 
                                                :attachment="'bottom-right'"/>
                                        </dd>
                                    </dl>                                    
                                </div>
                                <button type="button" class="btn btn-primary btn-sm" @click="addNewChild">Add New Child</button>
                            </div>
                        </div>

                        <!-- Notes -->
                        <div class="pmb-block">
                            <div class="pmbb-header">
                                <h2><i class="zmdi zmdi-account m-r-5"></i> General Notes</h2>
                            </div>
                            <div class="pmbb-body p-l-30">
                                <div class="pmbb-view">
                                    <textarea cols="30" rows="5" class="form-control" v-model="staff.notes"></textarea>
                                </div>
                            </div>
                        </div>

                        <!-- Submit -->
                        <div class="form-group m-l-30  m-b-30">
                            <button class="btn btn-success btn-icon-text m-l-30" :disabled="isProcessing">
                                <i class="zmdi zmdi-save"></i>
                                Save Staff
                                <span class="loader" v-if="isProcessing"></span>
                            </button>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
    import birthDatepicker from 'vue-birth-datepicker'
    import 'vue-birth-datepicker/dist/vueBirthDatepicker.css'

    import VueMonthlyPicker from 'vue-monthly-picker'

    export default {
        data(){
            return {
                myCroppa: {},
                monthLabels: this.$store.state.monthLabels,
                staff: {
                    title: "Mr",
                    firstName: "",
                    lastName: "",
                    otherNames: "",
                    gender: "",
                    dob: "",
                    maritalStatus: "",
                    marriageAnniversary: "",
                    spouse: "",
                    
                    telephone1: "",
                    telephone2: "",
                    
                    email1: "",
                    email2: "",

                    category: "",
                    assignment: "",
                    regionAssigned: "",
                    cityAssigned: "",
                    dateJoined: "",
                    ssnit: "",
                    
                    leadershipRoles: [
                        {
                            role: "",
                            started: "",
                            ended: "",
                            isCurrent: true,
                        }
                    ],
                    
                    passportNumber: "",
                    passportExpiry: "",
                    
                    ghanaCardNumber: "",
                    ghanaCardExpiry: "",

                    votersIdNumber: "",
                    votersIdExpiry: "",

                    healthInsuranceNumber: "",
                    healthInsuranceExpiry: "",

                    children: [
                        {
                            name: "",
                            gender: "",
                            dob: "",
                        }
                    ],

                    notes: "",
                    photo: "",

                },
                isProcessing: false
            }
        },
        methods: {
            handleCroppaInit(){
                this.staff.photo = this.$refs.staffPhoto.generateDataUrl()
            },
            handleCroppaFileChoose(){
                this.staff.photo = this.$refs.staffPhoto.generateDataUrl()
            },
            handleCroppaFileSizeExceed(){
                this.staff.photo = this.$refs.staffPhoto.generateDataUrl()
            },
            handleCroppaFileTypeMismatch(){
                this.staff.photo = this.$refs.staffPhoto.generateDataUrl()
            },
            handleNewImage(){
                this.staff.photo = this.$refs.staffPhoto.generateDataUrl()
            },
            handleImageRemove(){
                this.staff.photo = this.$refs.staffPhoto.generateDataUrl()
            },
            handleCroppaMove(){
                this.staff.photo = this.$refs.staffPhoto.generateDataUrl()
            },
            handleCroppaZoom(){
                this.staff.photo = this.$refs.staffPhoto.generateDataUrl()
            },
            onInputTelephone1({ number }) {
                this.staff.telephone1 = number.e164
            },
            onInputTelephone2({ number }) {
                this.staff.telephone2 = number.e164
            },
            addNewChild(){
                this.staff.children.push({
                    name: "",
                    gender: "",
                    dob: "",
                })
            },
            removeChild(i){
                this.staff.children.splice(i, 1)
            },
            addNewRole(){
                this.staff.leadershipRoles.push({
                    role: "",
                    started: "",
                    ended: "",
                    isCurrent: true,
                })
            },
            removeRole(i){
                this.staff.leadershipRoles.splice(i, 1)
            },
            selectPhoto(){
                this.$refs.staffPhoto.chooseFile()
            },
            saveStaff(){
                this.isProcessing = true
                this.$http.post("/staff/add", this.staff).then(response => {
                    if (response.data.code === 200) {

                        // clean the staff object
                        

                        // notification
                        this.$notify.success("Staff added successfully")

                        // remove the photo
                        this.$refs.staffPhoto.remove()

                        // view staff
                        this.$router.push("/staff/" + response.data.staffId)

                    } else {
                        this.$notify.error("Staff failed to add")
                    }

                    this.isProcessing = false

                })
            },
        },
        components: { 
            birthDatepicker,
            VueMonthlyPicker 
        },
    }
</script>